import service from '@/utils/request'

// 用户登录-新
export function newLogin (data) {
  return service({
    url: '/api/newLogin',
    method: 'post',
    data
  })
}

// 用户定制登录-使用滑动验证码
export function newCustomizedLogin (data) {
  return service({
    url: '/api/newLogin/newCustomizedLogin',
    method: 'post',
    data
  })
}

// 忘记密码找回-发送验证码
export function sendToPhoneOrEmail (data) {
  return service({
    url: '/api/login/sendToPhoneOrEmail',
    method: 'get',
    params: data
  })
}

// 忘记密码找回-提交验证码
export function submitCaptcha (data) {
  return service({
    url: '/api/login/submitCaptcha',
    method: 'post',
    data
  })
}

// 忘记密码找回-提交新密码
export function submitNewPassword (data) {
  return service({
    url: '/api/login/submitNewPassword',
    method: 'post',
    data
  })
}

// 密码修改-生成随机四位数图形验证码
export function randomCaptcha (data) {
  return service({
    url: '/api/login/randomCaptcha',
    method: 'get',
    params: data
  })
}

// 根据父级id获取地区选项
export function getAreaByParent (data) {
  return service({
    url: '/api/login/getAreaByParent',
    method: 'get',
    params: data
  })
}

// 注册
export function register (data) {
  return service({
    url: '/api/login/register',
    method: 'post',
    data
  })
}

// 获取滑动验证码的图片
export function getImgSwipe (data) {
  return service({
    url: '/api/login/getImgSwipe',
    method: 'get',
    params: data
  })
}

// 提交验证滑动验证码结果，返回0失败，1成功，2超过规定次数需要刷新
export function rstImgSwipe (data) {
  return service({
    url: '/api/login/rstImgSwipe',
    method: 'post',
    data
  })
}

// 登录展示状态
export function getViewStatus (data) {
  return service({
    url: '/api/thirdLevelPage/comment/getViewStatus',
    method: 'get',
    params: data
  })
}